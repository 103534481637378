@import url("https://fonts.googleapis.com/css2?family=Public+Sans:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&family=Ubuntu:wght@300;400;500;700&display=swap");

/*--New Figma Design Fonts--*/
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

@import "toastr";
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100..700;1,100..700&family=Outfit:wght@100..900&display=swap");

/*
font-family: 'Roboto', sans-serif;
font-family: 'Ubuntu', sans-serif;
font-family: 'Public Sans', sans-serif;
*/

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    transition: all 0.3s ease;
  }
  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #f1f5f9;
    transition: all 0.3s ease;
  }
  .scrollbar::-webkit-scrollbar-thumb {
    background: rgba(20, 184, 166, 0.7);
    border-radius: 100vh;
    transition: all 0.3s ease;
  }
  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: rgba(20, 184, 166, 1);
  }

  .scrollbar-0::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  .scrollbar-1::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
  }
  .scrollbar-1::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
  }
  .scrollbar-1::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.5);
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  @keyframes skeleton {
    50% {
      opacity: 0.5;
    }
  }
}

*,
*:focus {
  outline: none;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Public Sans", sans-serif;
  scroll-behavior: smooth;
}

/* .testimonial .swiper-slide{opacity: 0.2; transition: all 0.3s ease;}
.testimonial .swiper-slide.swiper-slide-active{opacity: 1;} */

.swiper-button-prev:after {
  content: "\f053" !important;
  font-family: "Font Awesome 6 Pro" !important;
  color: #64748b !important;
  font-weight: 300;
}
.swiper-button-next:after {
  content: "\f054" !important;
  font-family: "Font Awesome 6 Pro" !important;
  color: #64748b !important;
  font-weight: 300;
}

.hero .swiper-button-prev {
  left: 40px;
}
.hero .swiper-button-prev:after {
  color: #fff !important;
  font-size: 80px;
  font-weight: 100;
  opacity: 0.5;
}
.hero .swiper-button-next {
  right: 40px;
}
.hero .swiper-button-next:after {
  color: #fff !important;
  font-size: 80px;
  font-weight: 100;
  opacity: 0.5;
}

.swiper-slide-active .sub-title {
  animation: bounceInDown 1s;
}
.swiper-slide-active .title {
  animation: fadeInLeftBig 1.5s;
}
.swiper-slide-active .text {
  animation: zoomIn 1s;
}

ol {
  list-style-type: decimal;
}

.flatpickr-calendar {
  background-color: #fff !important;
}
.flatpickr-calendar .clear-button {
  cursor: pointer;
  margin-top: 0;
  padding: 10px;
  color: #14b8a6;
}
.flatpickr-calendar .flatpickr-innerContainer {
  border-width: 0 0 1px 0;
}
.flatpickr-calendar .flatpickr-days {
  border-width: 0;
}
.flatpickr-calendar .flatpickr-months .flatpickr-month {
  background-color: #14b8a6;
}
.flatpickr-calendar .flatpickr-current-month .flatpickr-monthDropdown-months {
  background-color: rgba(20, 184, 166, 0.7);
}
.flatpickr-calendar .flatpickr-weekdays {
  background-color: #14b8a6;
}
.flatpickr-calendar .flatpickr-weekdays span.flatpickr-weekday {
  background-color: #14b8a6;
  color: #fff;
  font-weight: 500;
}
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #14b8a6 !important;
  border-color: #14b8a6 !important;
}
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #14b8a6 !important;
  border-color: #14b8a6 !important;
}
.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
  -webkit-box-shadow: -10px 0 0 #14b8a6 !important;
  box-shadow: -10px 0 0 #14b8a6 !important;
}
.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  background: #f1f5f9 !important;
  border-color: #f1f5f9 !important;
}
.flatpickr-calendar .flatpickr-day.inRange {
  -webkit-box-shadow: -5px 0 0 #f1f5f9, 5px 0 0 #f1f5f9;
  box-shadow: -5px 0 0 #f1f5f9, 5px 0 0 #f1f5f9;
}
.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #14b8a6 !important;
}
.flatpickr-calendar .flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #ffffff;
}
.flatpickr-calendar .flatpickr-day.today:hover,
.flatpickr-calendar .flatpickr-day.today:focus {
  color: #14b8a6;
}
.flatpickr-calendar .flatpickr-day.selected.startRange:hover,
.flatpickr-calendar .flatpickr-day.startRange.startRange:hover,
.flatpickr-calendar .flatpickr-day.endRange.startRange:hover {
  color: #fff !important;
}

/* ********|| RESPONSIVE STARTS ||******** */

#toast-container > div {
  background-position: 10px !important;
  background-size: 16px !important;
  background-repeat: no-repeat !important;
  border-radius: 6px !important;
  box-shadow: 0 0 12px #999 !important;
  color: #fff !important;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80) !important;
  filter: alpha(opacity=80) !important;
  margin: 0 0 6px !important;
  opacity: 1 !important;
  overflow: hidden !important;
  padding: 6px 6px 6px 34px !important;
  pointer-events: auto !important;
  position: relative !important;
  width: 300px !important;
  font-size: 14px !important;
  font-weight: 200 !important;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1) !important;
}

.toast-error {
  background-color: #f44336 !important;
  height: auto;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.toast-warning {
  background-color: #ff9800 !important;
  height: auto;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.toast-success {
  background-color: #4caf50 !important;
  height: auto;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.toast-info {
  background-color: #03a9f4 !important;
  height: auto;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.toast-message {
  font-size: 13px;
  font-weight: 600;
}
.calendly-inline-widget {
  height: 800px !important;
}

.tooltip::after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #374151;
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
}

.company .swiper-slide {
  height: 176px;
}
.company .swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}

.editor-div {
  border: 1px solid #f1f1f1;
  margin-top: 16px;
  border-radius: 4px;
  overflow: hidden;
}
.rdw-editor-main {
  max-height: 600px;
}
.wrapperClassName {
  padding: 0 10px;
  border-style: hidden;
}
.rdw-editor-toolbar {
  padding: 0 !important;
  border: 1px solid transparent !important;
  border-bottom: 1px solid #f1f1f1 !important;
}

.blob {
  animation: pulse-purple 2s infinite;
}

.custom-tooltip {
  font-size: 14px !important;
}

@keyframes shine {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.animate-shine {
  animation: shine 1.5s linear infinite;
}

@keyframes pulse-purple {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(0, 12, 11, 0.9);
    box-shadow: 0 0 0 0 rgba(74, 163, 153, 0.9);
  }

  80% {
    -moz-box-shadow: 0 0 10px 15px rgba(0, 122, 255, 0);
    box-shadow: 0 0 10px 15px rgba(0, 122, 255, 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(0, 122, 255, 0);
    box-shadow: 0 0 0 0 rgba(0, 122, 255, 0);
  }
}
@media screen and (max-width: 1560px) {
}
@media screen and (max-width: 1440px) {
}
@media screen and (max-width: 1366px) {
}
@media screen and (max-width: 1280px) {
}
@media screen and (max-width: 1200px) {
}
@media screen and (min-width: 1025px) {
}
@media screen and (max-width: 1024px) {
}
@media screen and (max-width: 990px) {
  .hero .swiper-button-prev {
    left: 20px;
  }
  .hero .swiper-button-prev:after {
    color: #fff !important;
    font-size: 20px;
    font-weight: 100;
    opacity: 0.5;
  }
  .hero .swiper-button-next {
    right: 20px;
  }
  .hero .swiper-button-next:after {
    color: #fff !important;
    font-size: 20px;
    font-weight: 100;
    opacity: 0.5;
  }
}

@media screen and (max-width: 767px) {
}
@media screen and (max-width: 480px) {
}

@media screen and (max-width: 320px) {
}
/* ********|| RESPONSIVE ENDS ||******** */

/* ********|| Profile Card Skelton Loader ||******** */
/* Shimmer animation */
.shimmer {
  position: relative;
  overflow: hidden;
}

.shimmer::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
/* ********|| Profile Card Skelton Loader ||******** */

/* ********|| Date Picker Style ||******** */
.datepicker:last-child button[disabled] {
  text-decoration: none !important;
  color: #ec2d40;
  background-color: rgba(255, 255, 255, 0.5);
}

/* ********|| Date Picker Style  ||******** */

/* ********|| Tyniamc Footer hide Style ||******** */
.tox-statusbar__right-container {
  display: none !important;
}
/* ********|| Tyniamc Footer hide Style ||******** */

/* ********|| No Internet Connection Design||******** */
@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in {
  animation: fade-in 1s ease-in-out;
}

.animate-fade-in-delayed {
  animation: fade-in 1.5s ease-in-out;
}

@keyframes bounce-slow {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.animate-bounce-slow {
  animation: bounce-slow 2s infinite;
}

/* ********|| No Internet Connection Design||******** */

/* ********|| skelton animation Design||******** */
.shimmers {
  position: relative;
  overflow: hidden;
  background: linear-gradient(90deg, #bee2dd, #bee2dd);
  background-size: 200% 100%;
  animation: shimmer-gradient 2s infinite ease-in-out;
  border-radius: 8px; /* Smooth rounded corners */
}

.shimmers::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(255, 255, 255, 0.2) 100%
  );
  opacity: 0.8;
  animation: shimmer-overlay 1.5s infinite ease-in-out;
}

@keyframes shimmer-gradient {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

@keyframes shimmer-overlay {
  0% {
    transform: translateX(-150%);
  }
  100% {
    transform: translateX(150%);
  }
}

.break-words {
  word-break: break-word;
}

/* ********|| skelton animation Design||******** */

.clip-diagonal {
  clip-path: polygon(0 0, 100% 0, 100% 0%, 0 100%);
}

.trustItem .swiper-slide {
  height: 270px;
  width: 270px;
}
.trustItem .swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}

/* ********|| notification animation Design||******** */

@keyframes wiggle {
  0%, 100% { transform: rotate(0deg); }
  25% { transform: rotate(-10deg); }
  50% { transform: rotate(10deg); }
  75% { transform: rotate(-10deg); }
}
